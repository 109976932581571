import { EMessage, refreshTokenHook } from '../../../shared/hooks/refresh-token.hook';
import { IConfiguration, IUserLocalStorage } from '../../../shared/interfaces';
import { getSession } from '../../../shared/hooks/user-session.hook';
import { useParams } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import { useEffect } from 'react';

export function AuthUrl() {
   // configuration
   const { token, slugCompany } = useParams();
   const userSession: IUserLocalStorage | null = getSession();
   const configuration: string | null = localStorage.getItem('configuration');
   const configurationParse: IConfiguration | null = configuration ? JSON.parse(configuration) : null;

   useEffect(() => {
      if (!token || !slugCompany) return redirectToInvalidToken();
      const tokenDecoded = decodeTokenBase64(token);
      if (!tokenDecoded) return redirectToInvalidToken();
      const tokenValidated = validateJWT(tokenDecoded);
      if (!tokenValidated) return redirectToInvalidToken();
      localStorage.setItem('user', JSON.stringify({ ...userSession, token: tokenValidated }));
      if (configurationParse) {
         localStorage.setItem('configuration', JSON.stringify({ ...configurationParse, slugCompany }));
      }
      refreshTokenHook({ message: EMessage.Invalid, slugCompany });
   })

   /**
    * Redirects the user to the invalid token error page.
    */
   const redirectToInvalidToken = (): void => {
      window.location.href = `/error/invalid`;
   }

   /**
    * Decodes a base64 encoded token.
    *
    * @param token - The base64 encoded token to decode.
    * @returns The decoded token as a string, or undefined if decoding fails.
    */
   const decodeTokenBase64 = (token: string): string | undefined => {
      try {
         return atob(token);
      } catch (error) {
         console.error('error decoding the token:', error);
         redirectToInvalidToken();
         return;
      }
   };

   /**
    * Validates a JWT token.
    *
    * @param token - The JWT token to validate.
    * @returns The validated token if it is valid, otherwise undefined.
    */
   const validateJWT = (token: string): string | undefined => {
      try {
         jwt_decode(token);
         return token;
      } catch (error) {
         console.error('error validating the JWT:', error);
         redirectToInvalidToken();
         return;
      }
   };

   return <></>;
}
