import {
   updateReproductionPercentage,
   finishedVideo,
   pauseVideo,
   soundSwitch,
   replayVideo,
   setShowQuiz,
   setShowPracticalActivity
} from '../../../shared/redux/features/video/video.slice';
import { loadFinished } from '../../../shared/redux/features/load/load.slice';
import { eventTrack } from '../../../shared/hooks/event-track';
import { subtractSeconds } from '../../../shared/utilities';
import { RootState } from '../../../shared/redux/store';
import { useSelector, useDispatch } from 'react-redux';
import { useRef, useEffect, useState } from 'react';
import { BsFillPlayFill } from 'react-icons/bs';
import { IoVolumeMute } from 'react-icons/io5';
import { useLocation } from 'react-router-dom';
import { Oval } from 'react-loader-spinner';
import { t } from 'i18next';
import { ROUTES } from '../../constants';
import './video-player.css';

export function VideoPlayer(): JSX.Element {
   // configuration
   const location = useLocation();
   const dispatch = useDispatch();
   const validRoutes = ['home', 'all-modules', 'modules'];
   const videoHome: React.RefObject<HTMLVideoElement> = useRef(null);
   const countRef: React.MutableRefObject<number> = useRef<number>(0);
   const currentRoute = location.pathname.split('/')[2]; // domain/path1/path2

   // states redux
   const reproductionPercentage = useSelector((state: RootState) => state.video.reproductionPercentage);
   const percentageInteraction = useSelector((state: RootState) => state.video.percentageInteraction);
   const slugCompany = useSelector((state: RootState) => state.configuration.slugCompany);
   const repeatVideo = useSelector((state: RootState) => state.video.repeatVideo);
   const finished = useSelector((state: RootState) => state.video.finished);
   const paused = useSelector((state: RootState) => state.video.paused);
   const sound = useSelector((state: RootState) => state.video.sound);
   const url = useSelector((state: RootState) => state.video.url);

   // states
   const [count, setCount] = useState<number>(0);
   const [loading, setLoading] = useState<boolean>(true);
   const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null);

   // effects
   useEffect(() => {
      dispatch(finishedVideo(false));
      dispatch(setShowPracticalActivity(false));
      dispatch(setShowQuiz(false));
   }, [url]);

   useEffect(() => {
      if (percentageInteraction) {
         if (videoHome.current && paused) {
            videoHome.current.pause();
         }
      }
      if (!percentageInteraction && !sound && url) {
         if (videoHome.current) {
            if (!isNaN(reproductionPercentage) && isFinite(reproductionPercentage)) {
               videoHome.current.currentTime = (reproductionPercentage / 100) * videoHome.current.duration;
               dispatch(pauseVideo(false));
               videoHome.current.play();
            }
         }
      }
      if (!percentageInteraction && sound && url) {
         if (videoHome.current) {
            if (!isNaN(reproductionPercentage) && isFinite(reproductionPercentage)) {
               videoHome.current.currentTime = (reproductionPercentage / 100) * videoHome.current.duration;
               dispatch(pauseVideo(false));
               videoHome.current.play();
            }
         }
      }
   }, [percentageInteraction]);

   useEffect(() => {
      if (repeatVideo) {
         dispatch(pauseVideo(false));
         dispatch(replayVideo(false));

         setTimeout(() => {
            if (videoHome.current) {
               videoHome.current.currentTime = 0;
               videoHome.current.play();
               videoHome.current.play();
            }
         });
      }
   }, [repeatVideo]);

   useEffect(() => {
      if (sound && videoHome.current) {
         videoHome.current.muted = false;
         dispatch(pauseVideo(false));
      }
   }, [currentRoute]);

   useEffect(() => {
      if (paused && videoHome.current) {
         eventTrack('Pausar video', {}, slugCompany);
         videoHome.current.pause();
      }

      if (!paused) {
         setTimeout(() => {
            eventTrack('Reproducir video', {}, slugCompany);
         }, 2000);
      }
   }, [paused]);

   //methods
   const videoInteraction = () => {
      if (videoHome.current) {
         const videoElement = videoHome.current;
         const onVideoReady = () => {
            // remove the event listener to prevent multiple executions
            videoElement.removeEventListener('canplay', onVideoReady);
            // your video interaction logic here
            if (!sound) {
               videoElement.muted = false;
               dispatch(soundSwitch(true));
            }
            if (sound) {
               if (!paused) {
                  videoElement.muted = false;
                  videoElement.pause();
                  dispatch(pauseVideo(!paused));
               }
               if (paused) {
                  videoElement.muted = false;
                  videoElement.play();
                  dispatch(pauseVideo(!paused));
               }
            }
         };

         if (videoElement.readyState >= 2) {
            // the video is already ready to play
            onVideoReady();
         } else {
            // add an event listener to execute the function when the video is ready
            videoElement.addEventListener('canplay', onVideoReady);
         }
      }
   };

   const handleVideoLoadStart = () => {
      setLoading(true); // shows the charger when charging starts
   };

   const handleVideoCanPlayThrough = () => {
      setLoading(false); // hide the loader when the video is ready to play
   };

   const stopVideo = () => {
      if (videoHome.current) {
         const totalDuration = videoHome.current.duration;
         videoHome.current.currentTime = totalDuration - 1;
         setTimeout(() => {
            if (videoHome.current) videoHome.current.play();
         }, 500);
      }
   };

   const playHandler = (): void => {
      if (!count) {
         let interval = setInterval(() => {
            setCount((seconds) => {
               countRef.current = seconds + 1; // store the current value in the reference
               return countRef.current;
            });
         }, 1000);
         setIntervalId(interval);
      }
      dispatch(loadFinished());
   };

   const endedHandler = (): void => {
      if (intervalId) {
         eventTrack('Tiempo total reproducción', { totalTime: count, route: currentRoute }, slugCompany);
         if (currentRoute === 'home') {
            eventTrack('video_onboarding', {}, slugCompany);
         }
         setCount(0);
         setIntervalId(null);
         clearInterval(intervalId);
      }
      dispatch(setShowPracticalActivity(true))
      dispatch(setShowQuiz(true));
      dispatch(finishedVideo(true));
      dispatch(pauseVideo(true));
   };

   const currentTimeActions = (currentTime: number, totalDuration: number): void => {
      if (currentTime > 0 && !percentageInteraction) {
         if (currentTime >= subtractSeconds(totalDuration, 3)) {
            dispatch(setShowQuiz(true));
         }
         dispatch(updateReproductionPercentage(Number(parseInt(String((currentTime / totalDuration) * 100)))));
      }
   };

   if (validRoutes.includes(currentRoute)) {
      return (
         <div className='dvh-compatible absolute top-0 left-0 !h-[100dvh] w-full md:pl-[300px] md:flex md:justify-center md:items-center md:pt-[60px] pointer-events-none'>
            <div
               onClick={videoInteraction}
               className='dvh-compatible absolute top-0 left-0 right-0 !h-[100dvh] w-screen bg-black md:!h-[650px] md:w-[350px] md:relative md:rounded-lg md:shadow-md overflow-hidden pointer-events-auto'>
               <video
                  id='video-player'
                  ref={videoHome}
                  src={url}
                  preload='auto'
                  autoPlay={true}
                  playsInline={true}
                  muted={true}
                  controls={false}
                  onPlay={playHandler}
                  onEnded={endedHandler}
                  onCanPlayThrough={handleVideoCanPlayThrough}
                  onLoadStart={handleVideoLoadStart}
                  onTimeUpdate={(e: React.SyntheticEvent<HTMLVideoElement>) => {
                     currentTimeActions(e.currentTarget.currentTime, e.currentTarget.duration);
                  }}
                  className='absolute h-full w-full top-[50%] left-[50%] object-cover translate-y-[-50%] translate-x-[-50%]'
               />
               {url && paused && (
                  <div className='absolute right-0 left-0 top-0 bottom-0 h-[80px] w-[80px] m-auto flex justify-center items-center z-[1]'>
                     <div className='absolute h-[85px] w-[85px] bg-white/20 rounded-full' />
                     <div className='absolute h-[70px] w-[70px] bg-white rounded-full' />
                     <BsFillPlayFill className='absolute text-[60px] text-primary-color ml-2' />
                  </div>
               )}
               {url && !sound && !finished && (
                  <div className='absolute right-0 left-0 top-0 bottom-0 h-fit w-fit m-auto pb-[180px]'>
                     <div className='flex flex-col items-center bg-[#ffffffcc] text-black p-4 rounded-md'>
                        <IoVolumeMute className='text-[40px]' />
                        <p className='text-[20px] font-black'>{t('generics.tapToListen')}</p>
                     </div>
                  </div>
               )}
               {loading && (
                  <div className='absolute right-0 left-0 top-0 bottom-0 m-auto w-fit h-fit flex flex-col items-center text-white text-[18px] font-semibold'>
                     <Oval height={40} color='#ffffff' secondaryColor='#adb5bd' strokeWidth={6} strokeWidthSecondary={6} />
                     <h2>{t('generics.loading')}</h2>
                  </div>
               )}
               {/* <!-- Btn invisible to stop the video --> */}
               {url && (
                  <div
                     onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                        event.stopPropagation();
                        stopVideo();
                     }}
                     className='absolute top-0 right-0 z-[2] h-[50px] w-[50px]'
                  />
               )}
               {/* <!-- Btn skip video --> */}
               {url && currentRoute === ROUTES.HOME && (
                  <>
                     <button
                        onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                           event.stopPropagation();
                           stopVideo();
                        }}
                        className='absolute left-1/2 transform -translate-x-1/2
                         w-[160px] h-[40px] bg-transparent text-secondary-color rounded-2xl text-[18px] border border-[#fff] text-white z-10'
                         id='button-skip-video'>
                        {t('generics.skipVideo')}
                     </button>
                  </>
               )}
            </div>
         </div>
      );
   }

   return <></>;
}
